/*
 * Copyright 2023-2024 NXP
 */

import COLORS from "@/src/lib/colors";

const styles = {
  bodyItem: {
    height: "100%",
    width: "66%",
  },
  card: {
    backgroundColor: COLORS.grey[100],
    borderRadius: "10px",
    marginBottom: "1em",
    paddingBottom: "0",
  },
  containerText: { marginTop: "24px" },
  date: {
    float: {
      lg: "right",
      sm: "left",
    },
    width: {
      xs: "66%",
      lg: "55%",
    },
  },
  emptyDashHeader: {
    padding: "15px 0",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  displayInherit: { display: "inherit" },
  inline: { display: "flex", alignItems: "center" },
  headerSideItem: {
    margin: "10px",
    height: "36px",
    width: {
      sm: "350px",
      xs: "100%",
    },
    display: {
      xs: "none",
      sm: "initial",
    },
  },
  imageContainer: {
    display: "inline-flex",
    marginRight: "10px",
    verticalAlign: "middle",
    padding: 0,
  },
  image: {
    margin: {
      md: "initial",
      xs: "auto",
    },
    width: {
      md: "100%",
      sm: "50%",
      xs: "66%",
    },
  },
  linksContainer: { marginLeft: "24px" },
  media: {
    height: "210px",
    width: "188px",
  },
  menu: {
    display: "flex",
    flexDirection: {
      md: "column",
      sm: "row",
    },
    justifyContent: {
      md: "space-between",
      xs: "space-around",
    },
    alignItems: {
      lg: "initial",
      md: "center",
    },
  },
  menuItemText: {
    marginLeft: "10px",
    width: "75%",
    display: {
      xs: "none",
      lg: "initial",
    },
  },
  tabContainer: { marginTop: "24px" },
  tabsOuterContainer: (white: boolean) => ({
    width: "100%",
    backgroundColor: white ? "inherit" : COLORS.grey[100],
  }),
  tabsInnerContainer: { borderBottom: 1, borderColor: "divider" },
  tabTitle: { marginTop: "16px" },
  tabPanel: { p: 3 },
  title: {
    float: "left",
    height: "100%",
    width: "50%",
    margin: "auto",
  },
};

export default styles;
