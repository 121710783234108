/*
 * Copyright 2024 NXP
 */

import React, { useTransition } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { usePathname, useRouter } from "@/src/navigation";
import Image from "next/image";
import nxpLogo from "@/public/images/nxp-logo-color.svg";
import { LANGUAGE_LINKS } from "@/src/components/Views/Welcome/helpers";
import MenuIcon from "@mui/icons-material/Menu";
import LinkStyled from "@/src/components/UI/LinkStyled/LinkStyled";
import AppBar from "@mui/material/AppBar";
import NavMenu from "@/src/components/Views/Welcome/NavMenu";
import { useLocale, useTranslations } from "next-intl";
import mcuxVsCode from "@/public/images/MCUXpressoVSCodeIcon.png";
import ideIcon from "@/public/images/icon_ide.png";
import cfgIcon from "@/public/images/icon_cfg.png";
import secureProvIcon from "@/public/images/mcuxpresso_secure_prov_icon.png";
import sdkIcon from "@/public/images/icon_sdk.png";
import styles from "./styles";
import LinkWithLocale from "@/src/components/UI/LinkWithLocale/LinkWithLocale";

const WelcomeNavBar = () => {
  const [anchorLanguage, setAnchorLanguage] =
    React.useState<null | HTMLElement>(null);
  const [anchorSupport, setAnchorSupport] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [isPending, startTransition] = useTransition();

  const activeLocale = useLocale();
  const pathName = usePathname();
  const router = useRouter();

  const t = useTranslations("WelcomeNavBar");

  const NAV_LINKS = [
    {
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-for-visual-studio-code:MCUXPRESSO-VSC?tid=vanMCUXPRESSO-VSC",
      src: mcuxVsCode,
      alt: "vsc icon",
      title: t("vsCode"),
    },
    {
      href: "https://www.nxp.com/support/developer-resources/run-time-software/mcuxpresso-software-and-tools/mcuxpresso-integrated-development-environment-ide:MCUXpresso-IDE",
      src: ideIcon,
      alt: "ide icon",
      title: t("ide"),
    },
    {
      href: "https://www.nxp.com/support/developer-resources/run-time-software/mcuxpresso-software-and-tools/mcuxpresso-config-tools:MCUXpresso-Config-Tools",
      src: cfgIcon,
      alt: "cfg icon",
      title: t("configTools"),
    },
    {
      href: "https://www.nxp.com/mcuxpresso/secure",
      src: secureProvIcon,
      alt: "sec icon",
      title: t("provisioningTools"),
    },
    {
      href: "https://www.nxp.com/support/developer-resources/run-time-software/mcuxpresso-software-and-tools/mcuxpresso-software-development-kit-sdk:MCUXpresso-SDK",
      src: sdkIcon,
      alt: "sdk icon",
      title: t("sdk"),
    },
  ];

  const SUPPORT_LINKS = [
    {
      href: "https://community.nxp.com/community/mcuxpresso",
      title: t("softwareAndTools"),
    },
    {
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-sdk",
      title: t("sdk"),
    },
    {
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-ide",
      title: t("ide"),
    },
    {
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-config",
      title: t("configTools"),
    },
    {
      href: "https://community.nxp.com/t5/MCUXpresso-for-VSCode/bd-p/mcuxpresso-vscode",
      title: t("vsCode"),
    },
  ];

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLanguage(event.currentTarget);
  };
  const handleOpenSupportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSupport(event.currentTarget);
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorLanguage(null);
  };

  const handleCloseSupportMenu = () => {
    setAnchorSupport(null);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const handleLanguageChange = (lang: "en" | "zh") => {
    if (activeLocale != lang) {
      startTransition(() => {
        router.replace(pathName, { locale: lang });
      });
    }
  };
  return (
    <AppBar color={"transparent"} sx={styles.navBar} position={"relative"}>
      <Toolbar>
        <Stack
          width={"100%"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <LinkWithLocale href={"https://www.nxp.com"}>
            <Image
              src={nxpLogo}
              alt={"nxp logo"}
              width={120}
              title={"NXP Semiconductors"}
            />
          </LinkWithLocale>
          <List sx={styles.navIcons}>
            {NAV_LINKS.map((link) => (
              <ListItem key={link.href} disablePadding>
                <ListItemButton>
                  <LinkWithLocale href={link.href} target={"_blank"}>
                    <ListItemIcon>
                      <Image
                        src={link.src}
                        alt={link.alt}
                        width={60}
                        title={link.title}
                      />
                    </ListItemIcon>
                  </LinkWithLocale>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box sx={styles.hamburgerMenuContainer}>
            <IconButton
              size="large"
              aria-label="link-menu"
              onClick={handleOpenMenu}
              color="inherit"
            >
              <MenuIcon fontSize={"large"} />
            </IconButton>
            <Menu
              anchorEl={anchorMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorMenu)}
              onClose={handleCloseMenu}
              sx={styles.hamburgerMenu}
            >
              {NAV_LINKS.map((link) => (
                <LinkStyled
                  href={link.href}
                  underline={"none"}
                  color={"inherit"}
                  key={link.title}
                >
                  <MenuItem
                    key={link.href}
                    onClick={handleCloseMenu}
                    href={link.href}
                  >
                    <Image
                      src={link.src}
                      alt={link.alt}
                      width={30}
                      style={styles.hamburgerMenuImage}
                      title={link.title}
                    />
                    <Typography textAlign="center">{link.title}</Typography>
                  </MenuItem>
                </LinkStyled>
              ))}
            </Menu>
          </Box>
        </Stack>
      </Toolbar>
      <Toolbar variant={"dense"} sx={styles.denseNavbar}>
        <List disablePadding sx={styles.denseNavbarList}>
          <ListItemButton onClick={handleOpenLanguageMenu}>
            <Typography>{t("language")}</Typography>
          </ListItemButton>
          <ListItemButton onClick={handleOpenSupportMenu}>
            <Typography>{t("support")}</Typography>
          </ListItemButton>
        </List>
      </Toolbar>
      <NavMenu
        anchor={anchorLanguage}
        handleClose={handleCloseLanguageMenu}
        options={LANGUAGE_LINKS.map((link) => ({
          title: link.title,
          onClick: () => handleLanguageChange(link.locale as "en" | "zh"),
        }))}
      />
      <NavMenu
        anchor={anchorSupport}
        handleClose={handleCloseSupportMenu}
        options={SUPPORT_LINKS.map((link) => ({
          title: link.title,
          onClick: () => (window.location.href = link.href),
        }))}
      />
    </AppBar>
  );
};

export default WelcomeNavBar;
