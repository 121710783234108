/*
 * Copyright 2024 NXP
 */

import banner from "@/public/images/mcuxpresso-banner.png";

const styles = {
  bannerContainer: {
    minHeight: "520px",
    backgroundImage: `url("${banner.src}")`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  },
  bannerTextContainer: {
    color: "white",
    paddingY: "3rem",
  },
  bannerTitle: { xs: "center", md: "left" } as const,
  denseNavbar: {
    justifyContent: "end",
    backgroundColor: "#ffa700",
    boxShadow: "none",
    color: "white",
  },
  denseNavbarList: { display: "flex" },
  denseNavbarMenu: { mt: "45px" },
  navBar: { backgroundColor: "white" },
  navIcons: { display: { md: "flex", xs: "none" }, paddingY: 0 },
  hamburgerMenuContainer: { display: { xs: "flex", md: "none" } },
  hamburgerMenu: {
    display: { xs: "block", md: "none" },
  },
  hamburgerMenuImage: { marginRight: "10px" },
};

export default styles;
