/*
 * Copyright 2024 NXP
 */

import React from "react";
import { Grid, Typography } from "@mui/material";
import WelcomeButton from "@/src/components/Views/Welcome/WelcomeButton";
import MemoryTwoToneIcon from "@mui/icons-material/MemoryTwoTone";
import HouseIcon from "@mui/icons-material/House";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslations } from "next-intl";
import LinkWithLocale from "@/src/components/UI/LinkWithLocale/LinkWithLocale";

interface BannerLinksProps {
  hasAch: boolean;
  hasEbh: boolean;
}

const BannerLinks = ({ hasAch, hasEbh }: BannerLinksProps) => {
  const t = useTranslations("BannerLinks");

  const links = [
    {
      text: t("select"),
      icon: <MemoryTwoToneIcon />,
      href: "/select",
      target: "_self",
    },
    {
      text: t("dashboard"),
      icon: <HouseIcon />,
      href: "/dashboard",
      target: "_self",
    },
  ];

  if (hasEbh) {
    links.push({
      text: t("ebh"),
      icon: <OpenInNewIcon />,
      href: "/eb-hub",
      target: "_blank",
    });
  }

  if (hasAch) {
    links.push({
      text: t("apc"),
      icon: <OpenInNewIcon />,
      href: "/appcodehub",
      target: "_blank",
    });
  }

  return (
    <Grid container spacing={2} justifyContent={"space-between"}>
      {links.map((link) => (
        <Grid item xs={12} md={6} key={link.text}>
          <LinkWithLocale href={link.href} target={link.target}>
            <WelcomeButton
              fullWidth
              size={"large"}
              variant={"outlined"}
              startIcon={link.icon}
            >
              <Typography>{link.text}</Typography>
            </WelcomeButton>
          </LinkWithLocale>
        </Grid>
      ))}
    </Grid>
  );
};

export default BannerLinks;
