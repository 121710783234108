/*
 * Copyright 2023-2024 NXP
 */

const styles = {
  copyrightSection: {
    alignSelf: "center",
  },
  copyrightLink: { "&:hover": { color: "black" } },
  footerLinks: {
    "& > a": {
      "&:hover": { color: "black" },
      padding: "0 10px",
      borderRight: "1px solid rgba(0, 0, 0, 0.4)",
      "&:first-child": { paddingLeft: 0 },
      "&:last-child": {
        borderRight: "none",
      },
    },
    display: {
      xs: "none",
      md: "initial",
    },
  },
  footerContainer: (isBuilder: boolean) => ({
    // TODO: padding should be removed after removal of the collapsible side menu on builder
    padding: `0 ${isBuilder ? "50px" : "0"} 0 0`,
    color: "gray",
    marginTop: "40px",
  }),
};

export default styles;
