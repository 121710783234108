/*
 * Copyright 2023-2024 NXP
 */

"use client";

import React from "react";
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Stack,
  Typography,
} from "@mui/material";
import Footer from "@/src/components/layout/Footer/Footer";
import InfoTabs from "@/src/components/Views/Dashboard/EmptyDashboard/InfoTabs";
import WelcomeNavBar from "@/src/components/Views/Welcome/WelcomeNavBar";
import styles from "./styles";
import BannerLinks from "@/src/components/Views/Welcome/BannerLinks";
import { HubLinksQuery } from "@/web-builder/api";
import { useTranslations } from "next-intl";

interface WelcomeCockpitProps {
  staticData: HubLinksQuery;
}

export default function WelcomeCockpit({ staticData }: WelcomeCockpitProps) {
  const t = useTranslations("WelcomeCockpit");

  return (
    <Box minHeight={"100vh"}>
      <CssBaseline />
      <WelcomeNavBar />
      <Box sx={styles.bannerContainer}>
        <Container sx={styles.bannerTextContainer}>
          <Stack
            width={{
              xs: "100%",
              md: "85%",
              lg: "70%",
            }}
            gap={3}
          >
            <Typography variant={"h3"} textAlign={styles.bannerTitle}>
              {t("builder")}
            </Typography>
            <Typography variant={"h6"}>{t("description")}</Typography>
            <BannerLinks
              hasAch={staticData.appcodehub}
              hasEbh={staticData.ebhub}
            />
            <Alert severity={"info"} variant={"standard"} icon={false}>
              <Typography>{t("recommendation")}</Typography>
            </Alert>
          </Stack>
        </Container>
      </Box>
      <InfoTabs white />
      <Container>
        <Footer />
      </Container>
    </Box>
  );
}
