/*
 * Copyright 2024 NXP
 */

import React from "react";
import styles from "@/src/components/Views/Welcome/styles";
import { Menu, MenuItem, Typography } from "@mui/material";

interface NavMenuProps {
  anchor: null | HTMLElement;
  handleClose: () => void;
  options: {
    onClick: () => void;
    title: string;
  }[];
}

const NavMenu = ({ anchor, handleClose, options }: NavMenuProps) => {
  const origin = {
    vertical: "top" as const,
    horizontal: "right" as const,
  };

  return (
    <Menu
      sx={styles.denseNavbarMenu}
      anchorEl={anchor}
      anchorOrigin={origin}
      keepMounted
      transformOrigin={origin}
      open={Boolean(anchor)}
      onClose={handleClose}
    >
      {options.map((option) => (
        <MenuItem
          key={option.title}
          onClick={() => {
            option.onClick();
            handleClose();
          }}
        >
          <Typography textAlign="center">{option.title}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default NavMenu;
