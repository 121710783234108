/*
 * Copyright 2023-2024 NXP
 */

import * as React from "react";
import { ReactNode, SyntheticEvent } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Grid } from "@mui/material";
import styles from "./styles";
import Image from "next/image";

import LinkStyled from "@/src/components/UI/LinkStyled/LinkStyled";
import { useTranslations } from "next-intl";
import SDKIcon from "@/public/images/icon_sdk.png";
import IDEIcon from "@/public/images/icon_ide.png";
import ConfigIcon from "@/public/images/icon_cfg.png";
import SecureProvIcon from "@/public/images/mcuxpresso_secure_prov_icon.png";
import VSCIcon from "@/public/images/MCUXpressoVSCodeIcon.png";

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

interface InfoTabsProps {
  white?: boolean;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box sx={styles.tabPanel}>{children}</Box>
        </Container>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabProps {
  value: number;
}

const OverviewTab = ({ value }: TabProps) => {
  const t = useTranslations("OverviewTab");

  return (
    <TabPanel value={value} index={0}>
      <Box sx={styles.tabContainer}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"bold"}
        >
          {t("start")}
        </Typography>
        <Typography variant="h6" marginTop={2} fontWeight={"bold"}>
          {t("boardTitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t.rich("boardBody", {
            link: (chunks) => (
              <LinkStyled underline="hover" href={`/select`} variant="body1">
                {chunks}
              </LinkStyled>
            ),
          })}
        </Typography>
        <Typography variant="h6" marginTop={2} fontWeight={"bold"}>
          {t("dashboardTitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t.rich("dashboardBody", {
            link: (chunks) => (
              <LinkStyled underline="hover" href={`/dashboard`} variant="body1">
                {chunks}
              </LinkStyled>
            ),
          })}
        </Typography>
        <Typography variant="h6" marginTop={2} fontWeight={"bold"}>
          {t("alternativeTitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t.rich("alternativeBody", {
            link: (chunks) => (
              <LinkStyled
                underline={"hover"}
                variant={"body1"}
                href={"https://github.com/nxp-mcuxpresso/MCUX-SDK"}
              >
                {chunks}
              </LinkStyled>
            ),
            link2: (chunks) => (
              <LinkStyled
                underline={"hover"}
                variant={"body1"}
                href={
                  "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/open-cmsis-packs:OPEN-CMSIS-PACKS?tid=vanopen-cmsis-packs"
                }
              >
                {chunks}
              </LinkStyled>
            ),
          })}
        </Typography>
      </Box>
    </TabPanel>
  );
};

const SoftwareAndToolsTab = ({ value }: TabProps) => {
  const t = useTranslations("SoftwareAndToolsTab");

  const SOFTWARE_TOOLS = [
    {
      imageProps: {
        src: SDKIcon.src,
        alt: "sdk icon",
      },
      link: {
        href: "https://www.nxp.com/support/developer-resources/run-time-software/mcuxpresso-software-and-tools/mcuxpresso-software-development-kit-sdk:MCUXpresso-SDK",
        title: t("learnSdk"),
      },
      title: t("sdk"),
      text: t.rich("sdkDescription", {
        b: (chunks) => <b>{chunks}</b>,
      }),
    },
    {
      imageProps: {
        src: IDEIcon.src,
        alt: "ide icon",
      },
      link: {
        href: "https://www.nxp.com/support/developer-resources/run-time-software/mcuxpresso-software-and-tools/mcuxpresso-integrated-development-environment-ide:MCUXpresso-IDE",
        title: t("learnIde"),
      },
      title: t("ide"),
      text: t.rich("ideDescription", { b: (chunks) => <b>{chunks}</b> }),
    },
    {
      imageProps: {
        src: ConfigIcon.src,
        alt: "config icon",
      },
      link: {
        href: "https://www.nxp.com/support/developer-resources/run-time-software/mcuxpresso-software-and-tools/mcuxpresso-config-tools:MCUXpresso-Config-Tools",
        title: t("learnTools"),
      },
      title: t("tools"),
      text: t.rich("toolsDescription", {
        b: (chunks) => <b>{chunks}</b>,
      }),
    },
    {
      imageProps: {
        src: SecureProvIcon.src,
        alt: "secure provision icon",
      },
      link: {
        href: "https://www.nxp.com/mcuxpresso/secure",
        title: t("learnProvisioning"),
      },
      title: t("provisioning"),
      text: t("provisioningDescription"),
    },
    {
      imageProps: {
        src: VSCIcon.src,
        alt: "vsc icon",
      },
      link: {
        href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-for-visual-studio-code:MCUXPRESSO-VSC?tid=vanMCUXPRESSO-VSC",
        title: t("learnVS"),
      },
      title: t("vsCode"),
      text: t("vsDescription"),
    },
  ];

  return (
    <TabPanel value={value} index={1}>
      <Box sx={styles.tabContainer}>
        <Grid container spacing={4}>
          {SOFTWARE_TOOLS.map((tool) => (
            <Grid item xs={12} md={6} lg={3} key={tool.title}>
              <Box sx={styles.inline}>
                <Image
                  width={48}
                  height={48}
                  style={styles.imageContainer}
                  {...tool.imageProps}
                  alt={tool.imageProps.alt}
                />
                <Typography sx={styles.displayInherit} fontWeight={"bold"}>
                  {tool.title}
                </Typography>
              </Box>
              <Typography marginY={1.5} variant="body1" gutterBottom>
                {tool.text}
              </Typography>
              <Typography>
                <LinkStyled
                  underline="hover"
                  target="_blank"
                  href={tool.link.href}
                >
                  {tool.link.title}
                </LinkStyled>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </TabPanel>
  );
};

const DeveloperResourcesTab = ({ value }: TabProps) => {
  const t = useTranslations("InfoTabs");

  const DOCUMENTATION_LINKS = [
    {
      title: t("sdk"),
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-software-development-kit-sdk:MCUXpresso-SDK?tab=Documentation_Tab",
    },
    {
      title: t("ide"),
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-integrated-development-environment-ide:MCUXpresso-IDE?tab=Documentation_Tab",
    },
    {
      title: t("tools"),
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-config-tools-pins-clocks-peripherals:MCUXpresso-Config-Tools?tab=Documentation_Tab",
    },
    {
      title: t("provisioning"),
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-secure-provisioning-tool:MCUXPRESSO-SECURE-PROVISIONING?tab=Documentation_Tab",
    },
    {
      title: t("vsCode"),
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/mcuxpresso-for-visual-studio-code:MCUXPRESSO-VSC?tid=vanMCUXPRESSO-VSC",
    },
    {
      title: t("packs"),
      href: "https://www.nxp.com/design/software/development-software/mcuxpresso-software-and-tools-/open-cmsis-packs:OPEN-CMSIS-PACKS?tid=vanopen-cmsis-packs",
    },
  ];

  const COMMUNITY_SUPPORT_LINKS = [
    {
      title: t("sdk"),
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-sdk",
    },
    {
      title: t("ide"),
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-ide",
    },
    {
      title: t("tools"),
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-config",
    },
    {
      title: t("provisioning"),
      href: "https://community.nxp.com/community/mcuxpresso/mcuxpresso-secure-provisioning-tool",
    },
  ];

  return (
    <TabPanel value={value} index={2}>
      <Box
        textAlign={{ xs: "center", md: "left" }}
        sx={styles.tabContainer}
        display={"flex"}
        gap={4}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={"bold"}
          >
            {t("documentation")}
          </Typography>
          {DOCUMENTATION_LINKS.map((link) => (
            <LinkStyled
              underline="hover"
              target="_blank"
              href={link.href}
              key={link.title}
            >
              <Typography>{link.title}</Typography>
            </LinkStyled>
          ))}
        </Box>
        <Box>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={"bold"}
          >
            {t("communitySupport")}
          </Typography>
          {COMMUNITY_SUPPORT_LINKS.map((link) => (
            <LinkStyled
              underline="hover"
              target="_blank"
              href={link.href}
              key={link.title}
            >
              <Typography>{link.title}</Typography>
            </LinkStyled>
          ))}
        </Box>
      </Box>
    </TabPanel>
  );
};

const InfoTabs = ({ white }: InfoTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={styles.tabsOuterContainer(!!white)}>
      <Container>
        <Box sx={styles.tabsInnerContainer}>
          <Tabs variant="fullWidth" value={value} onChange={handleChange}>
            <Tab label={"Overview"} {...a11yProps(0)} />
            <Tab label={"Software and Tools"} {...a11yProps(1)} />
            <Tab label={"Developer Resources"} {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Container>
      <OverviewTab value={value} />
      <SoftwareAndToolsTab value={value} />
      <DeveloperResourcesTab value={value} />
    </Box>
  );
};

export default InfoTabs;
