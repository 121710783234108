/*
 * Copyright 2023-2024 NXP
 */

"use client";

import { Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import LinkStyled from "@/src/components/UI/LinkStyled/LinkStyled";
import styles from "./styles";
import { usePathname } from "@/src/navigation";
import { useTranslations } from "next-intl";

const Footer = () => {
  const isBuilder = usePathname() === "/builder";

  const t = useTranslations("Footer");

  const FOOTER_LINKS = [
    {
      id: "footer_privacy",
      href: "https://www.nxp.com/about/privacy:PRIVACYPRACTICES",
      text: t("privacy"),
    },
    {
      id: "footer_terms",
      href: "https://www.nxp.com/about/terms-of-use:TERMSOFUSE",
      text: t("terms"),
    },
    {
      id: "footer_contact",
      href: "https://www.nxp.com/about/contact-us:CONTACTUS",
      text: t("contact"),
    },
    {
      id: "footer_accessibility",
      href: "https://www.nxp.com/company/about-nxp/accessibility:ACCESSIBILITY",
      text: t("accessibility"),
    },
  ];

  return (
    <Toolbar variant={"dense"} style={styles.footerContainer(isBuilder)}>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography variant="body2" sx={styles.footerLinks}>
          {FOOTER_LINKS.map((link) => (
            <LinkStyled
              key={link.id}
              underline="hover"
              color={"inherit"}
              id={link.id}
              target="_blank"
              href={link.href}
            >
              {link.text}
            </LinkStyled>
          ))}
        </Typography>
        <Box
          sx={styles.copyrightSection}
          margin={{ xs: "auto", md: "initial" }}
        >
          <Typography variant="body2">
            &copy; {new Date().getFullYear()}{" "}
            <LinkStyled
              color={"inherit"}
              target="_blank"
              href="https://www.nxp.com"
              underline="hover"
              sx={styles.copyrightLink}
            >
              NXP Semiconductors.
            </LinkStyled>
            {t("rights")}
          </Typography>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default Footer;
