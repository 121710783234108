/*
 * Copyright 2024 NXP
 */

import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { purple } from "@mui/material/colors";

const WelcomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  textTransform: "none",
  backgroundColor: "rgba(255,255,255,0.15)",
  borderColor: "white",
  "&:hover": {
    borderColor: "white",
  },
}));

export default WelcomeButton;
